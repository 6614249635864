import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import videoReducer from './videoReducer';
import accountReducer from './accountReducer';
import videoUploaded from './videoUploaded';

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    video: videoReducer,
    user: accountReducer,
    videoUploaded,
});

export default reducer;
