import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { IconMenu2 } from '@tabler/icons';

import Upgrade from 'assets/images/subscription/upgrade.svg';
import useAuth from 'hooks/useAuth';

import LogoSection from '../LogoSection';
import SessionSection from './SessionSection';

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
    const theme = useTheme();
    const { user } = useAuth();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { formatMessage } = useIntl();
    const toggleMenuMessage = formatMessage({ id: 'toggle-menu' });

    return (
        <section
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                height: '45px',
            }}
        >
            <Box
                sx={{
                    width: 270,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <Box
                    component="span"
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'flex',
                            alignItems: 'center',
                        },
                        flexGrow: 1,
                    }}
                >
                    <LogoSection />
                </Box>
                <Tooltip title={toggleMenuMessage}>
                    <ButtonBase
                        sx={{ borderRadius: '12px', overflow: 'hidden' }}
                    >
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.dark.main
                                        : theme.palette.secondary.light,
                                color:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.secondary.main
                                        : theme.palette.secondary.dark,
                                '&:hover': {
                                    background:
                                        theme.palette.mode === 'dark'
                                            ? theme.palette.secondary.main
                                            : theme.palette.secondary.dark,
                                    color:
                                        theme.palette.mode === 'dark'
                                            ? theme.palette.secondary.light
                                            : theme.palette.secondary.light,
                                },
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                            aria-label={toggleMenuMessage}
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                </Tooltip>
            </Box>
            <Grid
                container
                alignItems="center"
                sx={{ paddingLeft: { xs: 1, md: 3, lg: 4 } }}
                spacing={{ xs: 1, sm: 2 }}
            >
                <Grid item>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '12px',
                            padding: {
                                xs: '2.5px',
                                sm: '2.5px 10px 4.5px 10px',
                            },
                            background: '#FFFFFF',
                            boxShadow: '0 24px 38px 0 #090F2512',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '36px',
                                height: '36px',
                            }}
                        >
                            <img
                                src={Upgrade}
                                alt="tariffs"
                                width={matchDownSM ? '35px' : '45px'}
                                height={matchDownSM ? '35px' : '45px'}
                            />
                        </div>
                        <Grid sx={{ marginLeft: { xs: '10px', sm: '20px' } }}>
                            <Grid
                                container
                                sx={{ display: { xs: 'block', sm: 'flex' } }}
                            >
                                <Grid item xs={12} sm="auto">
                                    <Grid
                                        container
                                        alignItems="center"
                                        borderRadius={3}
                                        spacing={1}
                                        sx={{
                                            paddingRight: {
                                                xs: '10px',
                                                sm: '20px',
                                            },
                                        }}
                                    >
                                        <Grid item>
                                            <Typography
                                                align="center"
                                                variant={
                                                    matchDownSM
                                                        ? 'caption'
                                                        : 'subtitle1'
                                                }
                                            >
                                                <FormattedMessage id="your-limit" />
                                                :
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                align="center"
                                                variant={
                                                    matchDownSM
                                                        ? 'caption'
                                                        : 'subtitle1'
                                                }
                                                color="#722BF5"
                                            >
                                                {user?.attemptsRemaining || 0}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item flex="1" overflow="hidden">
                    <Typography
                        align="right"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        title={user?.username}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        {user?.username}
                    </Typography>
                </Grid>
                <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item>
                            <SessionSection />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );
};

export default Header;
