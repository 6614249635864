import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

import { drawerWidth } from 'store/constant';
import type { DefaultRootStateProps } from 'types';

import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import SessionSection from '../Header/SessionSection';
import UserGuides from '../UserGuides';
import BugReport from '../BugReport';

export interface SidebarProps {
    drawerOpen?: boolean;
    drawerToggle?: () => void;
    window?: Window;
}

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const current = useSelector((state: DefaultRootStateProps) => state.user);

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd
                        ? 'calc(100vh - 56px)'
                        : 'calc(100vh - 75px)',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <MenuList />
                    <BugReport />
                </Box>
                <Box sx={{ mb: 2 }}>
                    <UserGuides version="menu" />
                </Box>
            </PerfectScrollbar>
            <Box sx={{ p: 2, display: { xs: 'block', md: 'none' } }}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                >
                    <Grid item flex="1" overflow="hidden" pr={2}>
                        <Typography
                            align="right"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            title={current.user?.username}
                        >
                            {current.user?.username}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <SessionSection />
                    </Grid>
                </Grid>
            </Box>
        </>
    );

    const container =
        window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                width: matchUpMd ? drawerWidth : 'auto',
            }}
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px',
                            height: 'calc(100% - 88px)',
                        },
                    },
                    // FIXME: I really do not know why this is needed.
                    // If we remove this, drawer is displayed on top of main
                    // content and prevents any interactions on xs screens.
                    right: 'unset!important',
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Sidebar;
