import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import useAuth from 'hooks/useAuth';
import menuItems from 'menu-items';
import type { GuardProps } from 'types';
import useDefaultPath from 'hooks/useDefaultPath';

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const arr = location?.pathname.split('/');
    if (location?.pathname.split('/').findIndex((item) => item === id)) {
        arr[location?.pathname.split('/').findIndex((item) => item === id)] =
            ':id';
    }

    const url = location?.pathname.split('/').findIndex((item) => item === id)
        ? arr.join('/')
        : location?.pathname;

    const deepSearch = (arr: any) => {
        for (let i = 0; i < arr?.length; i++) {
            if (
                Array.isArray(arr[i]?.children) &&
                deepSearch(arr[i]?.children)
            ) {
                return true;
            } else if (arr[i]?.url === url) {
                return true;
            } else {
                // ищем дальше
            }
        }
        return false;
    };

    const defaultPath = useDefaultPath();

    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps */
        let isUrl = false;
        if (localStorage.getItem('serviceToken')) {
            isUrl = deepSearch(
                menuItems.find(
                    (item: any) =>
                        item?.id !== 'unauth' && item?.id === user?.role
                )?.children
            );
        } else {
            isUrl = deepSearch(
                menuItems.find(
                    (item: any) => item?.id === 'unauth' && !user?.role
                )?.children
            );
        }

        // Если заказчик захочет убрать демо у всех кроме супер админа (такое уже было)
        // if (!isUrl) {
        //   user?.role === 'super_admin' || !user?.role
        //     ? navigate('/demo')
        //     : navigate('/dashboard')
        // }

        // Демо для всех (так как хотел заказчик изначально)
        if (!isUrl) {
            navigate(defaultPath);
        }
    }, [user?.role, defaultPath]);

    return children;
};

export default AuthGuard;
