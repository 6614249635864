import Typography from '@mui/material/Typography';

import menuItems from 'menu-items';
import useAuth from 'hooks/useAuth';

import NavGroup from './NavGroup';

const MenuList = () => {
    const { user } = useAuth();

    const navItems = menuItems
        .filter(
            (item) =>
                item.id === user?.role ||
                (user?.role === undefined && item.id === 'unauth')
        )
        .map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography
                            key={item.id}
                            variant="h6"
                            color="error"
                            align="center"
                        >
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default MenuList;
