import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';

export interface ICloseableDialogTitleProps {
    messageId: string;
    handleClose: () => void;
}

const CloseableDialogTitle = ({
    messageId,
    handleClose,
}: ICloseableDialogTitleProps) => (
    <DialogTitle>
        <Typography
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            minWidth="400px"
            variant="h3"
            component="span"
        >
            <FormattedMessage id={messageId} />
        </Typography>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
    </DialogTitle>
);

export default CloseableDialogTitle;
