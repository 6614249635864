import { useEffect, useState } from 'react';
import { matchPath, useLocation, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { IconTallymark1 } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

import { gridSpacing } from 'store/constant';
import type { NavItemType, OverrideIcon } from 'types';

const linkSX = {
    display: 'flex',
    color: 'grey.900',
    textDecoration: 'none',
    alignContent: 'center',
    alignItems: 'center',
};

export interface BreadCrumbsProps {
    navigation?: NavItemType[];
    rightAlign?: boolean;
    separator?: OverrideIcon;
}

const Breadcrumbs = ({
    navigation,
    rightAlign,
    separator,
}: BreadCrumbsProps) => {
    const theme = useTheme();

    const [items, setItems] = useState<NavItemType[]>([]);
    const location = useLocation();

    useEffect(() => {
        if (!navigation) {
            return;
        }

        const findInPath = (
            navItemObjects: NavItemType[]
        ): NavItemType[] | undefined => {
            for (const navItemObject of navItemObjects) {
                if (
                    navItemObject.url &&
                    matchPath(navItemObject.url, location.pathname)
                ) {
                    return [navItemObject];
                }

                if (navItemObject.children) {
                    const childrenInPath = findInPath(navItemObject.children);

                    if (!childrenInPath) {
                        continue;
                    }

                    return [navItemObject, ...childrenInPath];
                }
            }

            return undefined;
        };

        let newItems: NavItemType[] | undefined;
        for (const navItem of navigation) {
            if (
                navItem.type &&
                navItem.type === 'group' &&
                'children' in navItem
            ) {
                newItems = findInPath(navItem.children!);

                if (newItems) {
                    break;
                }
            }
        }

        if (!newItems) {
            return;
        }

        setItems(newItems);
    }, [navigation, location.pathname]);

    // item separator
    const SeparatorIcon = separator as any;
    const separatorIcon = separator ? (
        <SeparatorIcon stroke={1.5} size="1rem" />
    ) : (
        <IconTallymark1 stroke={1.5} size="1rem" />
    );

    return (
        <Card
            sx={{
                marginBottom: theme.spacing(gridSpacing),
                border: '1px solid',
                borderColor:
                    theme.palette.mode === 'dark'
                        ? theme.palette.background.default
                        : theme.palette.secondary.light,
                background: theme.palette.background.default,
            }}
        >
            <Box sx={{ p: 1, pl: 2 }}>
                <Grid
                    container
                    direction={rightAlign ? 'row' : 'column'}
                    justifyContent={rightAlign ? 'space-between' : 'flex-start'}
                    alignItems={rightAlign ? 'center' : 'flex-start'}
                    spacing={1}
                >
                    <Grid item>
                        <MuiBreadcrumbs
                            sx={{
                                '& .MuiBreadcrumbs-separator': {
                                    width: 16,
                                    ml: 1.25,
                                    mr: 1.25,
                                },
                            }}
                            aria-label="breadcrumb"
                            maxItems={8}
                            separator={separatorIcon}
                        >
                            <Typography
                                color="inherit"
                                variant="subtitle1"
                                sx={linkSX}
                                component={Link}
                                to="/"
                            >
                                <FormattedMessage id="home" />
                            </Typography>
                            {items.map((item, index) => {
                                if (!item.url) {
                                    return null;
                                }

                                if (index === items.length - 1) {
                                    return (
                                        <Typography
                                            key={item.url}
                                            variant="subtitle1"
                                            sx={linkSX}
                                        >
                                            {item.title}
                                        </Typography>
                                    );
                                }

                                return (
                                    <Typography
                                        key={item.url}
                                        variant="subtitle1"
                                        sx={linkSX}
                                        component={Link}
                                        to={item.url}
                                    >
                                        {item.title}
                                    </Typography>
                                );
                            })}
                        </MuiBreadcrumbs>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

export default Breadcrumbs;
