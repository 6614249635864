import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Formik } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import axios from 'axios';

import CloseableDialogTitle from 'ui-component/extended/CloseableDialogTitle';
import CheckboxInput from 'ui-component/checkbox-input';
import FileInput from 'ui-component/file-input';
import getErrors from 'utils/get-errors';
import TextInput from 'ui-component/text-input';
import { allServices } from 'services/all/account';
import type { FileReference } from 'services/all/account';

const MAX_SIZE = 10 * 1024 * 1024;

const uploadFiles = async (files: File[]): Promise<FileReference[]> => {
    if (!files.length) {
        return [];
    }
    const uploadDestinations = await allServices.getFileUploadTargets(
        files.length
    );
    try {
        return await Promise.all(
            files.map(async (file, index) => {
                const { url, fields: destFields } = uploadDestinations[index];
                const postData = new FormData();
                for (const key in destFields) {
                    postData.append(key, destFields[key]);
                }
                postData.append('file', file);
                await axios.post(url, postData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: '',
                    },
                });
                return {
                    name: file.name,
                    s3_key: destFields.key,
                };
            })
        );
    } catch (err) {
        throw new Error('Failed to upload files.');
    }
};

const BugReportModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { formatMessage } = useIntl();

    const initialValues = {
        summary: '',
        description: '',
        files: [] as File[],
        notify: true,
    };
    type FormType = typeof initialValues;

    const schema = Yup.object().shape({
        summary: Yup.string()
            .min(20, 'Please type at least 20 characters')
            .max(255, 'Please stay under 255 characters limit')
            .required('Please provide the summary'),
        description: Yup.string()
            .min(50, 'Please provide more details')
            .required('Please provide the description'),
        files: Yup.mixed(),
        notify: Yup.bool(),
    });
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <CloseableDialogTitle
                messageId="have-any-issues"
                handleClose={onClose}
            />

            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        const files = await uploadFiles(values.files);
                        await allServices.sendBugReport({
                            summary: values.summary,
                            description: values.description,
                            files,
                            inform_customer: values.notify,
                        });
                        setStatus({ success: true });
                        setSubmitting(false);
                        onClose();
                    } catch (error: any) {
                        const errors = getErrors({
                            response: error?.response?.data ?? error,
                            isKeyOfFormState: (
                                key: any
                            ): key is keyof FormType => key in initialValues,
                            expectedErrorFields: [
                                'errors',
                                'non_field_errors',
                                'detail',
                                'message',
                            ],
                        });

                        setErrors(errors as any);
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextInput
                                        fieldName="summary"
                                        labelKey="bug-summary"
                                        placeholder={formatMessage({
                                            id: 'bug-summary-placeholder',
                                        })}
                                        type="text"
                                        id="summary"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fieldName="description"
                                        labelKey="bug-description"
                                        placeholder={formatMessage({
                                            id: 'bug-description-placeholder',
                                        })}
                                        type="text"
                                        id="description"
                                        multiline
                                        minRows={3}
                                        required
                                    />
                                    <FormHelperText>
                                        <FormattedMessage id="bug-description-help" />
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput
                                        fieldName="files"
                                        labelKey="bug-report-files"
                                        multiple
                                        maxSize={MAX_SIZE}
                                        maxFiles={10}
                                        accept={{
                                            'image/*': [
                                                '.png',
                                                '.jpg',
                                                '.jpeg',
                                                '.tiff',
                                                '.gif',
                                            ],
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxInput
                                        fieldName="notify"
                                        labelKey="bug-notify-me"
                                        id="notify"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={onClose}
                                color="secondary"
                                variant="outlined"
                                disabled={isSubmitting}
                            >
                                <FormattedMessage id="close" />
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                <FormattedMessage id="submit" />
                            </LoadingButton>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};

const BugReport = () => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <>
            <Box display="flex">
                <Button
                    variant="text"
                    sx={{ flexGrow: 1 }}
                    onClick={() => {
                        setModalOpen(true);
                    }}
                    startIcon={<BugReportOutlinedIcon />}
                >
                    <FormattedMessage id="have-any-issues" />
                </Button>
            </Box>
            <BugReportModal
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
            />
        </>
    );
};
export default BugReport;
