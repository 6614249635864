import { Link } from 'react-router-dom';
import ButtonBase from '@mui/material/ButtonBase';

import Logo from 'ui-component/Logo';
import useDefaultPath from 'hooks/useDefaultPath';

const LogoSection = () => {
    const defaultPath = useDefaultPath();
    return (
        <ButtonBase disableRipple component={Link} to={defaultPath}>
            <Logo />
        </ButtonBase>
    );
};

export default LogoSection;
