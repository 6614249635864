import { contactUs } from './common';

const unauth = {
    id: 'unauth',
    title: '',
    type: 'group',
    children: [contactUs],
};

export default unauth;
