import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconLogout } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { encodePassedState } from 'hooks/usePassedState';
import ButtonWithTooltip from 'ui-component/extended/ButtonWithTooltip';

const SessionSection = () => {
    const { logout, user } = useAuth();
    const location = useLocation();

    const handleLogout = async () => {
        try {
            logout();
        } catch (err) {
            console.error(err);
        }
    };

    const searchParams = `?passed_state=${encodePassedState({
        redirect_uri: [location.pathname, location.search, location.hash].join(
            ''
        ),
    })}`;

    return user ? (
        <>
            <Box>
                <ButtonWithTooltip
                    messageId="logout"
                    animate
                    variant="outlined"
                    sx={{
                        width: '45px',
                        height: '45px',
                        color: '#722BF5',
                        backgroundColor: 'transparent',
                        borderColor: '#EDE7F6',
                        display: 'flex',
                        alignItems: 'center',
                        padding: 1,
                        minWidth: 'auto',
                    }}
                    onClick={handleLogout}
                >
                    <IconLogout stroke={1.5} size="1.3rem" />
                </ButtonWithTooltip>
            </Box>
        </>
    ) : (
        <>
            <Button component={Link} to={`/login${searchParams}`}>
                <FormattedMessage id="sign-in" />
            </Button>
            <Button component={Link} to={`/register${searchParams}`}>
                <FormattedMessage id="sign-up" />
            </Button>
        </>
    );
};

export default SessionSection;
