import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

import Logo from 'ui-component/Logo';

const WarningListItem = ({
    children,
}: React.PropsWithChildren<Record<never, never>>) => (
    <ListItem>
        <ListItemIcon>
            <WarningIcon fontSize="medium" color="warning" />
        </ListItemIcon>
        <ListItemText
            primaryTypographyProps={{
                fontSize: { xs: '0.875rem', sm: '1.125rem' },
            }}
        >
            {children}
        </ListItemText>
    </ListItem>
);

const GameRules = ({ useLogo }: { useLogo: boolean }) => (
    <Stack sx={{ height: '100%' }}>
        <Typography variant="h3" mb={2}>
            Welcome to the Simpleem AI Pitch Game!
        </Typography>

        <Typography
            variant="body2"
            sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}
        >
            We all strive for success and aim to secure that winning deal, but
            have you ever considered if you utilize your body language, voice,
            and words effectively? <b>Test your pitching skills</b> and see how
            you perform via Simpleem AI models learned on 10,000+ hours of real
            sales conversations.
        </Typography>

        <List>
            <WarningListItem>
                You will have one minute to pitch virtually to an audience.
                Please use a microphone and position yourself properly in front
                of the camera.
            </WarningListItem>
            <WarningListItem>
                Your goal is to close deal by keeping your audience engaged and
                attentive during the entire 1-minute pitch.
            </WarningListItem>
            <WarningListItem>
                Be cautious, as the Simpleem AI models are designed to detect
                any red flags or distractions that may arise during your pitch.
                Any such occurrences will result in a loss of that attempt.
            </WarningListItem>
        </List>

        <Typography variant="h3" component="span">
            Good luck, and may you deliver a winning pitch!
        </Typography>

        {useLogo ? (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow="1"
            >
                <Logo />
            </Box>
        ) : null}
    </Stack>
);

export default GameRules;
