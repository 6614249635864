import isObject from './is-object';

/**
 *
 * This method parses error response from backend. It assumes the response is a JSON.
 *
 * Supported cases:
 *
 * 1. Map of string arrays:
 * ```
 * {
 *      errors: {
 *          email: ['Already taken.', 'Not an email.'],
 *          password: ['Too short.', 'Too weak.'],
 *      }
 * }
 * ```
 * 2. String array:
 * ```
 * {
 *      non_field_errors: ['Operation timeout.']
 * }
 * ```
 * 3.String:
 * ```
 * {
 *      detail: 'Unknown error.'
 * }
 * ```
 * 4. Their combination
 *
 * @param args.response - actual response from the server containing error fields.
 * @param args.isKeyOfFormState - predicate deciding whether or not the key is related to a form sent. Related keys will end up in a separate error fields, the rest will go into `error.submit` field.
 * @param args.expectedErrorFields -filter out unnecessary fields from the root response.
 */
const getErrors = <
    Errors extends Record<string, string> & { submit?: string | undefined },
>({
    response,
    isKeyOfFormState = (_key: string | number | symbol): _key is keyof Errors =>
        false,
    expectedErrorFields = Object.keys(response),
}: {
    response: Record<string, unknown>;
    isKeyOfFormState?: (key: string | number | symbol) => key is keyof Errors;
    expectedErrorFields?: string[];
}) => {
    const errors: Partial<Errors> = {};

    for (const errorField of expectedErrorFields) {
        const errorFieldValue = response[errorField];
        if (isObject(errorFieldValue)) {
            for (const errorKey in errorFieldValue) {
                const errorValues = errorFieldValue[errorKey];
                if (!Array.isArray(errorValues)) {
                    continue;
                }

                if (isKeyOfFormState(errorKey)) {
                    errors[errorKey as keyof Errors] = errorValues.join(
                        ' '
                    ) as Errors[keyof Errors];
                } else {
                    errors.submit = `${errors.submit || ''}${errorValues.join(
                        ' '
                    )} `;
                }
            }
        } else if (Array.isArray(errorFieldValue)) {
            errors.submit = `${errors.submit || ''}${errorFieldValue.join(
                ' '
            )} `;
        } else if (typeof errorFieldValue === 'string') {
            errors.submit = `${errors.submit || ''}${errorFieldValue} `;
        }
    }

    return errors;
};

export default getErrors;
