import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import '_mockApis';
import App from 'App';
import { store } from 'store';
import 'assets/scss/style.scss';
import config from 'config';

const root = createRoot(document.getElementById('root')!);
root.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>
);
