// action - state management
import type { DefaultRootStateProps } from 'types';
import type { VideoListStateProps } from 'types/list';

import { GET_VIDEOS } from './actions';

const initialState: DefaultRootStateProps['video'] = {
    count: 0,
    next: null,
    previous: null,
    results: [],
};

export interface ListReducerActionProps {
    type: string;
    payload?: VideoListStateProps;
}

const videoReducer = (state = initialState, action: ListReducerActionProps) => {
    switch (action.type) {
        case GET_VIDEOS: {
            const payload = {
                results: action.payload?.results,
                count: action.payload?.count || 0,
                next: action.payload?.next,
                previous: action.payload?.previous,
            };
            return (state = Object.assign({}, state, payload));
        }
        default: {
            return { ...state };
        }
    }
};

export default videoReducer;
