import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import CloseableDialogTitle from 'ui-component/extended/CloseableDialogTitle';
import GameRules from 'views/pages/GameRules';

const UserGuides = ({ version }: { version: 'fab' | 'menu' }) => {
    const helpIconStateKey = 'helpIcon__state';
    const storedIconState = localStorage.getItem(helpIconStateKey) as any;
    const [iconState, setIconState] = useState<'folded' | 'expanded'>(
        storedIconState || 'expanded'
    );
    const [modalOpen, setModalOpen] = useState(false);
    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            {version === 'fab' && (
                <>
                    {iconState === 'expanded' && (
                        <Tooltip title={<FormattedMessage id="open-help" />}>
                            <Fab
                                sx={{
                                    position: 'fixed',
                                    right: '18px',
                                    bottom: '36px',
                                    backgroundColor: '#1b1b1bbb',
                                    color: '#fff',
                                    '&:hover': { color: '#000' },
                                }}
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                            >
                                <QuestionMarkTwoToneIcon fontSize="large" />
                            </Fab>
                        </Tooltip>
                    )}
                    <Tooltip
                        title={
                            <FormattedMessage
                                id={
                                    iconState === 'folded'
                                        ? 'show-help-icon'
                                        : 'hide-help-icon'
                                }
                            />
                        }
                    >
                        <Button
                            sx={{
                                position: 'fixed',
                                right: iconState === 'expanded' ? '18px' : 0,
                                bottom: iconState === 'expanded' ? '4px' : 0,
                                px: 0,
                                py: '2px',
                                minWidth: '56px',
                                ...(iconState === 'expanded'
                                    ? {}
                                    : { borderRadius: 0 }),
                            }}
                            onClick={() => {
                                const newState =
                                    iconState === 'folded'
                                        ? 'expanded'
                                        : 'folded';
                                setIconState(newState);
                                localStorage.setItem(
                                    helpIconStateKey,
                                    newState
                                );
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            <FormattedMessage
                                id={iconState === 'folded' ? 'show' : 'hide'}
                            />
                        </Button>
                    </Tooltip>
                </>
            )}
            {version === 'menu' && (
                <Box display="flex">
                    <Button
                        variant="outlined"
                        sx={{ flexGrow: 1 }}
                        onClick={() => {
                            setModalOpen(true);
                        }}
                        startIcon={<QuestionMarkTwoToneIcon />}
                    >
                        <FormattedMessage id="need-any-help" />
                    </Button>
                </Box>
            )}
            <Dialog
                open={modalOpen}
                onClose={closeModal}
                fullWidth
                maxWidth="md"
                PaperProps={{ sx: { overflowX: 'hidden' } }}
            >
                <CloseableDialogTitle
                    messageId="guides-title"
                    handleClose={closeModal}
                />
                <DialogContent>
                    <GameRules useLogo={false} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>
                        <FormattedMessage id="close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default UserGuides;
