/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import useAlert from 'hooks/useAlert';
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import type { initialLoginContextProps, KeyedObject } from 'types';
import type { JWTContextType, RegisterArgsWithPassedState } from 'types/auth';
import config from 'config';
import axiosServices from 'utils/axios';
import { allServices } from 'services';
import type {
    IChangePasswordRequest,
    IUserUpdateRequest,
} from 'services/all/account';
import { PKCE_STORE_KEY } from 'utils/pkce';

const DOMAIN = config.domain;
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axiosServices.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('serviceTokenSuper');
        localStorage.removeItem('serviceTokenAdmin');
        delete axiosServices.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common.Authorization;
    }
};

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const dispat4 = useDispatch();
    const serviceToken = window.localStorage.getItem('serviceToken');
    const navigate = useNavigate();
    const { showAlert } = useAlert();

    const refreshUserInfo = async () => {
        const user = await allServices.getAccountInfo();
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user,
            },
        });
        dispat4({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user,
            },
        });
        const { deleted_count } = await allServices.cleanupGames();
        if (deleted_count > 0) {
            showAlert('error', 'last-game-error');
            refreshUserInfo();
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    await refreshUserInfo();
                } else {
                    setSession(null);
                    dispatch({
                        type: LOGOUT,
                    });
                }
            } catch (err) {
                console.error('Account Me Err', err);
                dispatch({
                    type: LOGOUT,
                });
            }
        };

        init();
    }, [serviceToken]);

    useEffect(() => {
        const interceptorId = axios.interceptors.response.use(
            undefined,
            (error) => {
                if (
                    error.response?.status === 401 &&
                    error.response?.data?.code === 'token_not_valid' &&
                    state.isLoggedIn
                ) {
                    console.error(
                        'Token is invalid or expired. Logging out...'
                    );
                    setSession(null);
                    dispatch({
                        type: LOGOUT,
                    });
                }

                throw error;
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptorId);
        };
    }, [state.isLoggedIn]);

    const login = async (
        email: string,
        password: string,
        redirectUri?: string | undefined
    ) => {
        const loginResponse = await allServices.loginUser(email, password);
        const { access } = loginResponse;
        setSession(access);
        if (redirectUri) {
            navigate(redirectUri);
        }
        refreshUserInfo();
        return loginResponse;
    };

    const activate = async (
        userId: string,
        activationCode: string,
        redirectUri: string | undefined
    ) => {
        const activateUserResponse = await allServices.activateUser(
            userId,
            activationCode
        );
        const serviceToken = activateUserResponse.access;
        if (serviceToken) {
            navigate(redirectUri || '/activated');

            setSession(serviceToken);
            await refreshUserInfo();
        } else {
            setSession(null);
            throw new Error("Couldn't activate");
        }
    };

    const register = async ({
        passed_state,
        ...registerArgs
    }: RegisterArgsWithPassedState) => {
        let registerResponse;
        try {
            registerResponse = await allServices.register(registerArgs);
        } catch (err: any) {
            if (typeof err.response === 'object') {
                throw err.response.data;
            } else {
                throw new Error('Unknown error');
            }
        }

        try {
            const confirmationEmailResponse =
                await allServices.sendConfirmationEmail(
                    registerResponse.uuid,
                    passed_state
                );
            return confirmationEmailResponse.target;
        } catch (err: any) {
            if (typeof err.response === 'object') {
                throw err.response.data;
            } else {
                throw new Error('Failed to send confirmation email');
            }
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
        sessionStorage.clear();
    };

    const googleSignUp = async (credential: string) => {
        const response = await allServices.signupGoogle(credential);

        const access_token = response.access;
        if (access_token) {
            setSession(access_token);
            await refreshUserInfo();
        }
    };
    const googleLogIn = async (credential: string) => {
        const response = await allServices.loginGoogle(credential);

        const access_token = response.access;
        if (access_token) {
            setSession(access_token);
            await refreshUserInfo();
        }
    };

    const loadSimpleemPKCEVerifier = (): string => {
        const verifier = sessionStorage.getItem(PKCE_STORE_KEY);
        if (!verifier) {
            throw new Error('Verifier not found.');
        }
        return verifier;
    };

    const simpleemSignUp = async (credential: string) => {
        const verifier = loadSimpleemPKCEVerifier();
        const response = await allServices.signupSimpleem(credential, verifier);

        const access_token = response.access;
        if (access_token) {
            setSession(access_token);
            await refreshUserInfo();
        }
    };
    const simpleemLogIn = async (credential: string) => {
        const verifier = loadSimpleemPKCEVerifier();
        const response = await allServices.loginSimpleem(credential, verifier);

        const access_token = response.access;
        if (access_token) {
            setSession(access_token);
            await refreshUserInfo();
        }
    };

    const resetPassword = async (
        password: string,
        confirm_password: string
    ) => {
        try {
            await axiosServices.post(`${DOMAIN}/api/v1/accounts/reset/`, {
                password,
                confirm_password,
            });

            if (!password && !confirm_password) {
                return [
                    400,
                    { message: 'Enter Your password and confirm_password' },
                ];
            }
            if (password !== confirm_password) {
                return [
                    400,
                    {
                        message:
                            'Your password and confirm_password is not ecvule',
                    },
                ];
            }
        } catch (err: any) {
            return err.response;
        }
    };

    const changePassword = async (request: IChangePasswordRequest) => {
        const response = await allServices.changePassword(request);
        const access_token = response.access;
        setSession(access_token);
    };

    const updateProfile = async (request: IUserUpdateRequest) => {
        await allServices.updateUser(request);
        await refreshUserInfo();
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                resetPassword,
                updateProfile,
                activate,
                googleSignUp,
                googleLogIn,
                simpleemSignUp,
                simpleemLogIn,
                changePassword,
                refreshUserInfo,
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
