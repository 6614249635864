import config from 'config';

import useAuth from './useAuth';

const useDefaultPath = () => {
    const { user } = useAuth();

    return config.defaultPath[user ? 'user' : 'unauth'];
};

export default useDefaultPath;
