import axios from 'axios';

import config from 'config';
import type { VideoInfo } from '_mockApis/video/details';
import type { UserStatus } from '_mockApis/user-profile/details';
import type { UserProfile } from '_mockApis/user-profile/types';
import type { ETagData } from 'types';
import type { VideoListData } from 'types/user';

function getFullURL(urlWithoutPrexix: string): string {
    return `${DOMAIN}${API_PREFIX}${urlWithoutPrexix}`;
}

const DOMAIN = config.domain;
const API_PREFIX = '/api/v1/';

export interface IChangePasswordRequest {
    old_password: string;
    new_password1: string;
    new_password2: string;
}

const changePassword = async (request: IChangePasswordRequest) => {
    const response = await axios.post(
        getFullURL('accounts/password/change/'),
        request
    );
    return response.data;
};

interface IUserUpdateRequestBase {
    first_name: string;
    last_name: string;
    purpose: string;
    purpose_raw?: string | null; // Only if purpose === 'other', never required.
    country: string;
    phone?: string | null;
}

interface IOtherFields {
    title: string;
    industry: string;
    company_name: string;
}

type IIndividualUserUpdateRequest = IUserUpdateRequestBase & {
    legal_status: 'individual';
} & Partial<IOtherFields>;
type IOrgUserUpdateRequest = IUserUpdateRequestBase & {
    legal_status: 'legal_entity';
} & IOtherFields;

export type IUserUpdateRequest =
    | IIndividualUserUpdateRequest
    | IOrgUserUpdateRequest;

const updateUser = async (request: IUserUpdateRequest) => {
    const response = await axios.put(
        getFullURL('accounts/current-user/'),
        request
    );
    return response.data;
};

const createFile = async (filename: string) => {
    const data = new FormData();
    data.append('filename', filename);
    try {
        const response = await axios.post(
            getFullURL('videos/chunked-upload/'),
            data
        );
        return response;
    } catch (err: any) {
        return err.response;
    }
};

const uploadFile = async ({
    ...request
}: {
    chunk: any;
    chunkNo: number;
    fileId: string;
}) => {
    try {
        const chunkDataResponse = await axios.post(
            getFullURL(`videos/chunked-upload/${request.fileId}/new-chunk/`),
            { chunk_number: request.chunkNo }
        );
        const { upload_to: uploadTo } = chunkDataResponse.data;

        const response = await axios.put(uploadTo, request.chunk, {
            transformRequest: [
                (data, headers) => {
                    delete headers.Authorization;
                    return data;
                },
            ],
        });
        return response;
    } catch (err: any) {
        return err.response;
    }
};

const chunkedCompleted = async (uuid: string, etags: ETagData[]) => {
    try {
        const response = await axios.post(
            getFullURL(`videos/chunked-upload/${uuid}/completed/`),
            { uploaded_parts: etags }
        );
        return response;
    } catch (err: any) {
        return err.response;
    }
};

const createVideo = async ({
    ...request
}: {
    name: string;
    file?: string;
    subject?: string;
    group_class?: string;
    lesson_start?: Date | null;
    submit?: null;
    video_result?: string;
    embeddings: object;
}) => {
    try {
        const response = await axios.post(getFullURL('videos/video/'), request);
        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getVideosDemoVideos = async () => {
    try {
        const response = await axios.get(getFullURL('videos/demo/videos/'), {
            headers: { Authorization: '' },
        });
        return response.data;
    } catch (err: any) {
        return err.response;
    }
};

const connectZoom = async (access_token: string | null) => {
    try {
        const response = await axios.post(
            getFullURL('accounts/connect-zoom/'),
            {
                access_token,
            }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const connectWebex = async (code: string, state: string) => {
    try {
        return await axios.post(getFullURL('accounts/connect-webex/'), {
            code,
            state,
        });
    } catch (err: any) {
        return err.response;
    }
};

const getResults = async () => {
    try {
        const response = await axios.get(
            getFullURL('videos/organization-result-videos/')
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const GOOGLE_DOMAIN = 'https://www.googleapis.com/calendar/v3';
const getGoogleMeetings = async (access_token: string) => {
    try {
        const response = await axios.get(
            `${GOOGLE_DOMAIN}/users/me/calendarList`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getSubjects = async () => {
    try {
        const response = await axios.get(
            getFullURL('videos/organization-subjects/')
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getClases = async () => {
    try {
        const response = await axios.get(
            getFullURL('videos/organization-group-classes/')
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getVideoInfo = async (uuid?: string): Promise<VideoInfo> => {
    const response = await axios.get(getFullURL(`videos/video/${uuid}/`));
    return response.data;
};

const getDemoVideoInfo = async (uuid?: string) => {
    try {
        const response = await axios.get(getFullURL('videos/demo/video/'), {
            headers: { Authorization: '' },
        });
        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getVideoGraphs = async ({
    ...request
}: {
    uuid?: string;
    graph_type?: string;
    embedding?: string;
    detailed?: string;
}) => {
    try {
        const id = request.uuid;
        delete request.uuid;
        const response = await axios.get(
            getFullURL(`videos/video/${id}/graphs/`),
            {
                params: request,
            }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getDemoVideoGraphs = async ({
    ...request
}: {
    uuid?: string;
    graph_type?: string;
    embedding?: string;
    detailed?: string;
}) => {
    try {
        delete request.uuid;
        const response = await axios.get(getFullURL('videos/demo/graphs/'), {
            params: request,
            headers: { Authorization: '' },
        });

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getVideoEmbeddingGraphs = async ({
    ...request
}: {
    uuid?: string;
    embedding?: string;
}) => {
    try {
        const id = request.uuid;
        delete request.uuid;
        const response = await axios.get(
            getFullURL(`videos/video/${id}/graphs-embeddings/`),
            { params: request }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const putVideoInfo = async (video?: VideoInfo, uuid?: string) => {
    try {
        const response = await axios.patch(
            getFullURL(`videos/video/${uuid}/`),
            video
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

export interface EmbeddingBaseStudent {
    embeddingUuid: string;
    name?: string;
    studentUuid?: string;
    personStatus?: UserStatus;
}

export interface EmbeddingNewStudent extends EmbeddingBaseStudent {
    name: string;
}
export interface EmbeddingExistingStudent extends EmbeddingBaseStudent {
    studentUuid: string;
}

export interface EmbeddingStatus extends EmbeddingBaseStudent {
    personStatus: UserStatus;
}

const deleteVideo = async (uuid?: string) => {
    try {
        const response = await axios.delete(
            getFullURL(`videos/video/${uuid}/`)
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getStudents = async (search?: string) => {
    try {
        const response = await axios.get(getFullURL('embeddings/students/'), {
            params: {
                search,
            },
        });

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getStudentById = async (uuid?: string) => {
    try {
        const response = await axios.get(
            getFullURL(`embeddings/students/${uuid}/`)
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const saveStripeSubInfo = async (request: { price_uuid: string }) => {
    try {
        const response = await axios.post(
            getFullURL('payments/save-stripe-sub-info/'),
            {
                price_uuid: request?.price_uuid,
            }
        );

        return response.data;
    } catch (err: any) {
        return err.response;
    }
};

const updatePaymentMethod = async () => {
    const response = await axios.post(
        getFullURL('payments/update-payment-method/')
    );
    return response.data;
};

const getDurationGraph = async (
    start_date?: Date | null,
    end_date?: Date | null,
    period?: string | null
) => {
    try {
        const response = await axios.get(
            getFullURL('dashboard/teacher/duration-graph/'),
            {
                params: {
                    start_date,
                    end_date,
                    period,
                    tz_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getSimpleemScoreGraph = async (
    start_date?: Date | null,
    end_date?: Date | null,
    period?: string | null
) => {
    try {
        const response = await axios.get(
            getFullURL('dashboard/teacher/simpleem-score-graph/'),
            {
                params: {
                    start_date,
                    end_date,
                    period,
                    tz_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getVideoResultGraph = async (
    start_date?: Date | null,
    end_date?: Date | null,
    period?: string | null
) => {
    try {
        const response = await axios.get(
            getFullURL('dashboard/teacher/video-result-graph/'),
            {
                params: {
                    start_date,
                    end_date,
                    period,
                    tz_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const getVideoLegends = async () => {
    try {
        const response = await axios.get(getFullURL('videos/legends/'));
        return response;
    } catch (err: any) {
        return err.response;
    }
};

export enum VideoDataType {
    emo_flags_v2 = 'emo_flags_v2',
    emo_flags = 'emo_flags',
    voice_flags = 'voice_flags',
    // audio_events = 'audio_events',
    audio_analysis = 'audio_analysis',
    emo_positive_negative_flags = 'emo_positive_negative_flags',
}

const getVideoData = async (
    uuid: string,
    params: { person_id: string; type: VideoDataType }
) => {
    try {
        const response = await axios.get(
            getFullURL(`videos/video/${uuid}/data/`),
            {
                params,
            }
        );

        return response;
    } catch (err: any) {
        return err.response;
    }
};

const reprocessVideo = async (uuid: string) => {
    try {
        const response = await axios.get(
            getFullURL(`videos/video/${uuid}/restart/`)
        );
        return response;
    } catch (err: any) {
        return err.response;
    }
};

export interface IPaymentPlanPrice {
    uuid: string;
    unit_amount: number;
    limit_second: number;
    limit_user: number;
    limit_gb: number;
    interval: 'month' | 'year';
    discount: number;
    price_per_month: number;
    upgrade_cost: number | null;
}

export interface IPaymentPlan {
    uuid: string;
    name: string;
    description: string;
    price_list: IPaymentPlanPrice[];
    level: number;
}

const getPlans = async (): Promise<{ results: IPaymentPlan[] }> => {
    const response = await axios.get(getFullURL('tariffs/'));
    return response.data;
};

const getEnterprisePlan = async () => {
    const response = await axios.get(getFullURL('tariffs/enterprise-plan/'));
    return response.data;
};

const cancelSubscription = async () => {
    const response = await axios.post(getFullURL('payments/unsubscribe/'));

    return response.data;
};

const getExtraMinutesList = async () => {
    const response = await axios.get(getFullURL('tariffs/extra-minutes/'));
    return response.data;
};

const getExtraMinutesPaymentUrl = async (uuid: string) => {
    const response = await axios.post(
        getFullURL(`tariffs/extra-minutes/${uuid}/pay/`),
        {}
    );
    return response.data;
};

const signupGoogle = async (credential: string) => {
    const response = await axios.post(getFullURL(`/accounts/signup-google/`), {
        credential,
    });
    return response.data;
};

const loginGoogle = async (credential: string) => {
    const response = await axios.post(getFullURL(`/accounts/login-google/`), {
        credential,
    });
    return response.data;
};

const signupSimpleem = async (credential: string, verifier: string) => {
    const response = await axios.post(
        getFullURL(`/accounts/signup-simpleem/`),
        {
            credential,
            verifier,
        }
    );
    return response.data;
};

const loginSimpleem = async (credential: string, verifier: string) => {
    const response = await axios.post(getFullURL(`/accounts/login-simpleem/`), {
        credential,
        verifier,
    });
    return response.data;
};

const getDemoVideos = async () => {
    const response = await axios.get(getFullURL(`/core/landing-videos/`));
    return response.data;
};

const getAccountsOptions = async () => {
    const response = await axios.get(
        getFullURL('accounts/utils/dropdown-options/')
    );
    return response.data;
};

const connectSalesforce = async (code: string, state: string) => {
    const response = await axios.post(
        getFullURL('accounts/connect-salesforce/'),
        {
            code,
            state,
        }
    );
    return response.data;
};

const getCRMRecordList = async () => {
    const response = await axios.get(
        getFullURL('videos/video/crm/record/list/')
    );
    return response.data;
};

const disconnectSalesforce = async () => {
    const response = await axios.post(
        getFullURL('accounts/salesforce-disconnect/'),
        ''
    );
    return response.data;
};

// Functions and types below this marker are the only up-to-date.
// Sorry for this clutter.

interface PaginatedResponse<T> {
    count: number;
    previous: null;
    next: null;
    results: T[];
}
export interface ILoginResponse extends UserProfile {
    access: string;
}

const patchUser = <T extends UserProfile>(user: Omit<T, 'role'>): T => {
    return {
        ...user,
        role: 'user',
    } as any;
};

const loginUser = async (
    email: string,
    password: string
): Promise<ILoginResponse> => {
    delete axios.defaults.headers.common.Authorization;
    const response = await axios.post(getFullURL('accounts/account/login/'), {
        email,
        password,
    });
    return patchUser<ILoginResponse>(response.data);
};

export interface RegisterArgs {
    email: string;
    username: string;
    password: string;
    password_confirm: string;
}

const register = async (data: RegisterArgs): Promise<UserProfile> => {
    delete axios.defaults.headers.common.Authorization;
    const response = await axios.post(getFullURL('accounts/account/'), data);
    return patchUser(response.data);
};

const sendConfirmationEmail = async (
    userId: string,
    passed_state: string | undefined
): Promise<{ target: string }> => {
    const response = await axios.post(
        getFullURL(`accounts/account/${userId}/send-confirmation-email/`),
        { passed_state }
    );
    return response.data;
};

const activateUser = async (
    userId: string,
    activationCode: string
): Promise<ILoginResponse> => {
    const response = await axios.post(
        getFullURL(`accounts/account/${userId}/activate/`),
        {
            activation_code: activationCode,
        }
    );
    return patchUser<ILoginResponse>(response.data);
};

interface ConfirmResetPasswordRequest {
    user: string;
    new_password1: string;
    new_password2: string;
    token: string;
}

const forgotPassword = async (email?: string) => {
    delete axios.defaults.headers.common.Authorization;
    const response = await axios.post(
        getFullURL('accounts/account/password/reset/'),
        { email },
        { headers: { Authorization: null } }
    );
    return response.data;
};

const confirmResetPassword = async (data: ConfirmResetPasswordRequest) => {
    delete axios.defaults.headers.common.Authorization;
    const response = await axios.post(
        getFullURL('accounts/account/password/reset-confirm/'),
        data,
        { headers: { Authorization: null } }
    );
    return response.data;
};

const getLeaderBoard = async () => {
    const response = await axios.get(
        getFullURL('accounts/account/leaderboard/'),
        { headers: { Authorization: null } }
    );
    return response.data;
};

const getAccountInfo = async (): Promise<UserProfile> => {
    const response = await axios.get(getFullURL(`accounts/account/me/`));
    return patchUser(response.data);
};

export interface IICEServer {
    urls: string[];
    username?: string;
    credential?: string;
}
export interface IStreamerParameters {
    channelARN: string;
    region: string;
    endpoint: string;
    iceServers: IICEServer[];
    expectedClients: string[];
}
const requestGameStart = async (): Promise<IStreamerParameters> => {
    const response = await axios.post(getFullURL('games/game/init/'), {});
    return response.data;
};

export interface PurchaseOption {
    attemptsCount: number;
    priceCents: number;
}
const getPurchaseOptions = async (): Promise<
    PaginatedResponse<PurchaseOption>
> => {
    const response = await axios.get(getFullURL('payments/purchase-option/'));
    return response.data;
};

const startBuyNewAttempts = async (
    attemptsCount: number
): Promise<{ sessionUrl: string }> => {
    const response = await axios.post(
        getFullURL('payments/payment/checkout-create/'),
        {
            attempts_count: attemptsCount,
        }
    );
    return response.data;
};

const getVideos = async (
    params: URLSearchParams,
    abortController?: AbortController
): Promise<PaginatedResponse<VideoListData>> => {
    const response = await axios.get(getFullURL('games/game/'), {
        params,
        signal: abortController?.signal,
    });
    return response.data;
};
const getSummaryPdf = async (): Promise<Blob> => {
    const response = await axios.get(getFullURL(`games/game/download/`), {
        responseType: 'blob',
    });
    return response.data;
};
const cleanupGames = async (): Promise<{ deleted_count: number }> => {
    const response = await axios.post(getFullURL(`games/game/cleanup/`));
    return response.data;
};

interface UploadTarget {
    url: string;
    fields: Record<string, string>;
}
const getFileUploadTargets = async (
    files_count: number
): Promise<UploadTarget[]> => {
    const response = await axios.post(
        getFullURL('core/bug-report/upload-urls/'),
        { files_count }
    );
    return response.data.destinations;
};

export interface FileReference {
    name: string;
    s3_key: string;
}
const sendBugReport = async (options: {
    summary: string;
    description: string;
    files: FileReference[];
    inform_customer: boolean;
}): Promise<void> => {
    await axios.post(getFullURL('core/bug-report/'), options);
};

export const allServices = {
    changePassword,
    updateUser,
    createFile,
    uploadFile,
    chunkedCompleted,
    getVideos,
    getSummaryPdf,
    cleanupGames,
    createVideo,
    connectZoom,
    connectWebex,
    getResults,
    getSubjects,
    getGoogleMeetings,
    getClases,
    getVideoInfo,
    getVideoGraphs,
    getVideoEmbeddingGraphs,
    putVideoInfo,
    getStudents,
    saveStripeSubInfo,
    updatePaymentMethod,
    deleteVideo,
    getDurationGraph,
    getSimpleemScoreGraph,
    getVideoResultGraph,
    getVideosDemoVideos,
    getDemoVideoGraphs,
    getDemoVideoInfo,
    getStudentById,
    forgotPassword,
    getVideoLegends,
    getVideoData,
    reprocessVideo,
    getPlans,
    register,
    sendConfirmationEmail,
    activateUser,
    confirmResetPassword,
    cancelSubscription,
    getEnterprisePlan,
    getExtraMinutesList,
    getExtraMinutesPaymentUrl,
    signupGoogle,
    loginGoogle,
    signupSimpleem,
    loginSimpleem,
    getDemoVideos,
    getAccountsOptions,
    connectSalesforce,
    getCRMRecordList,
    disconnectSalesforce,
    loginUser,
    getLeaderBoard,
    getAccountInfo,
    requestGameStart,
    getPurchaseOptions,
    startBuyNewAttempts,
    getFileUploadTargets,
    sendBugReport,
};
