import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const VideoData = Loadable(lazy(async () => await import('views/video/data')));
const VideoList = Loadable(lazy(async () => await import('views/video')));

const OtherRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/video-list',
            element: <VideoList />,
        },
        {
            path: '/video-list/video-info/:id',
            element: <VideoData />,
        },
    ],
};

export default OtherRoutes;
