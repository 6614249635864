import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useState } from 'react';
import Identicon from 'identicon.js';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SpaIcon from '@mui/icons-material/Spa';
import PerfectScrollbar from 'react-perfect-scrollbar';

import GameRules from 'views/pages/GameRules';
import { allServices } from 'services';
import sha256 from 'utils/sha256';

interface LeaderBoardMember {
    uuid: string; // UUID
    username: string;
    best_score: number;
    avatar_url: string;
}

const RegisterWrapper = ({
    children,
    showRules = true,
}: React.PropsWithChildren<{ showRules?: boolean }>) => {
    const [leaderBoard, setLeaderBoard] = useState<LeaderBoardMember[]>();

    useEffect(() => {
        allServices
            .getLeaderBoard()
            .then(
                async ({
                    results,
                }: {
                    results: Array<Omit<LeaderBoardMember, 'avatar_url'>>;
                }) =>
                    await Promise.all(
                        results.map(async (member) => ({
                            ...member,
                            avatar_url: `data:image/png;base64, ${new Identicon(
                                await sha256(member.uuid),
                                240
                            ).toString()}`,
                        }))
                    )
            )
            .then(setLeaderBoard);
    }, []);

    let list = null;

    if (leaderBoard) {
        if (leaderBoard.length === 0) {
            list = (
                <Stack direction="column" alignItems="center">
                    <SpaIcon fontSize="large" />
                    <Typography>Looks a bit empty here</Typography>
                </Stack>
            );
        } else {
            list = (
                <List>
                    {leaderBoard.map(
                        ({ avatar_url, uuid, username, best_score }, index) => (
                            <ListItem
                                key={uuid}
                                secondaryAction={
                                    <div
                                        style={{
                                            width: 40,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {index === 0 ? (
                                            <EmojiEventsIcon
                                                htmlColor="gold"
                                                fontSize="large"
                                            />
                                        ) : index === 1 ? (
                                            <MilitaryTechIcon htmlColor="silver" />
                                        ) : index === 2 ? (
                                            <MilitaryTechIcon
                                                htmlColor="#CD7F32"
                                                fontSize="small"
                                            />
                                        ) : null}
                                    </div>
                                }
                                style={{
                                    border: `1px solid ${
                                        index === 0 ? 'gold' : 'transparent'
                                    }`,
                                    borderRadius: 10,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={avatar_url}
                                        style={{
                                            width: index === 0 ? 40 : 30,
                                            height: index === 0 ? 40 : 30,
                                        }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant={
                                                index === 0
                                                    ? 'subtitle1'
                                                    : 'subtitle2'
                                            }
                                        >
                                            {username}
                                        </Typography>
                                    }
                                    secondary={best_score}
                                />
                            </ListItem>
                        )
                    )}
                </List>
            );
        }
    }

    return (
        <PerfectScrollbar
            component="div"
            style={{
                height: '100vh',
                width: '100vw',
                background: '#EBECF0',
            }}
        >
            <Box
                display="flex"
                sx={{
                    minHeight: '100%',
                    px: { xs: 0, sm: 2 },
                    py: { xs: 1, sm: 2 },
                }}
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12} md={5} lg={4}>
                        <Grid
                            container
                            spacing={2}
                            sx={{ height: 'calc(100% + 16px)' }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={12}
                                display="flex"
                                flexGrow="1"
                            >
                                <Card elevation={3} sx={{ width: '100%' }}>
                                    <CardContent sx={{ height: '100%' }}>
                                        {children}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12} display="flex">
                                <Card elevation={3} sx={{ flexGrow: 1 }}>
                                    <CardContent sx={{ height: '100%' }}>
                                        <Typography variant="h2">
                                            Leaderboard
                                        </Typography>
                                        <Box
                                            sx={{ height: '100%' }}
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                        >
                                            {list}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showRules ? (
                        <Grid item xs={12} md={5}>
                            <Card elevation={3} sx={{ height: '100%' }}>
                                <CardContent sx={{ height: '100%' }}>
                                    <GameRules
                                        useLogo={Boolean(
                                            leaderBoard &&
                                                leaderBoard.length >= 1
                                        )}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    ) : null}
                </Grid>
            </Box>
        </PerfectScrollbar>
    );
};
export default RegisterWrapper;
