import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg
            width="121"
            height="26"
            viewBox="0 0 121 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M90.356 14.5431C91.0935 14.5431 91.7257 14.2797 92.2525 13.7529C92.8056 13.1998 93.0822 12.5545 93.0822 11.817C93.0822 11.0795 92.8188 10.4473 92.292 9.92052C91.7652 9.36739 91.1199 9.09082 90.356 9.09082C89.5922 9.09082 88.9469 9.36739 88.4201 9.92052C87.8933 10.4473 87.6299 11.0795 87.6299 11.817C87.6299 12.5545 87.8933 13.1998 88.4201 13.7529C88.9732 14.2797 89.6185 14.5431 90.356 14.5431Z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M74.3043 14.5431C75.0418 14.5431 75.6739 14.2797 76.2007 13.7529C76.7538 13.1998 77.0304 12.5545 77.0304 11.817C77.0304 11.0795 76.767 10.4473 76.2402 9.92052C75.7134 9.36739 75.0681 9.09082 74.3043 9.09082C73.5404 9.09082 72.8951 9.36739 72.3683 9.92052C71.8415 10.4473 71.5781 11.0795 71.5781 11.817C71.5781 12.5545 71.8415 13.1998 72.3683 13.7529C72.9214 14.2797 73.5668 14.5431 74.3043 14.5431Z"
                fill={theme.palette.primary.main}
            />
            <rect
                x="76.46"
                y="0.595947"
                width="3.0121"
                height="8.6169"
                transform="rotate(79.2606 76.46 0.595947)"
                fill={theme.palette.primary.main}
            />
            <rect
                x="76.46"
                y="0.595947"
                width="3.0121"
                height="8.6169"
                transform="rotate(79.2606 76.46 0.595947)"
                fill={theme.palette.primary.main}
            />
            <rect
                width="3.0121"
                height="8.6169"
                transform="matrix(-0.186342 0.982485 0.982485 0.186342 87.7642 0.595947)"
                fill={theme.palette.primary.main}
            />
            <path
                d="M4.10154 10.1951C4.10154 10.6199 4.32318 10.9709 4.76644 11.2479C5.20971 11.5065 5.85614 11.7558 6.70574 11.9959C7.35217 12.1437 7.91549 12.3007 8.39569 12.4669C8.8759 12.6331 9.38381 12.8824 9.91942 13.2149C10.455 13.5289 10.8614 13.9537 11.1384 14.4893C11.4339 15.0064 11.5724 15.6159 11.554 16.3178C11.554 17.6476 11.0368 18.6911 10.0025 19.4483C8.96824 20.2056 7.68462 20.5842 6.15165 20.5842C4.78491 20.5842 3.6121 20.2979 2.63322 19.7254C1.65434 19.1528 0.943267 18.3586 0.5 17.3428L3.07649 15.8468C3.53823 17.1766 4.56328 17.8415 6.15165 17.8415C7.72156 17.8415 8.50651 17.3243 8.50651 16.2901C8.50651 15.5328 7.62921 14.9418 5.87461 14.517C5.20971 14.3508 4.64639 14.1845 4.18466 14.0183C3.74139 13.8521 3.24271 13.612 2.68863 13.298C2.15302 12.984 1.73745 12.5685 1.44194 12.0513C1.1649 11.5342 1.03561 10.9339 1.05408 10.2505C1.05408 8.97615 1.53429 7.9511 2.4947 7.17538C3.47358 6.39966 4.68333 6.01181 6.12395 6.01181C7.26905 6.01181 8.28487 6.27038 9.17141 6.78752C10.0764 7.2862 10.769 7.98804 11.2492 8.89304L8.72814 10.306C8.26641 9.2532 7.39834 8.72682 6.12395 8.72682C5.53293 8.72682 5.04349 8.8561 4.65563 9.11467C4.28624 9.37325 4.10154 9.7334 4.10154 10.1951Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
            <path
                d="M16.8476 3.74006C16.4782 4.10945 16.035 4.29415 15.5178 4.29415C15.0007 4.29415 14.5482 4.10945 14.1603 3.74006C13.7909 3.3522 13.6062 2.8997 13.6062 2.38256C13.6062 1.86541 13.7909 1.42215 14.1603 1.05276C14.5297 0.664899 14.9822 0.47097 15.5178 0.47097C16.0534 0.47097 16.5059 0.664899 16.8753 1.05276C17.2447 1.42215 17.4294 1.86541 17.4294 2.38256C17.4294 2.8997 17.2355 3.3522 16.8476 3.74006ZM17.0139 20.224H14.0218V6.37196H17.0139V20.224Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
            <path
                d="M35.6144 6.01181C37.1843 6.01181 38.4495 6.51972 39.4099 7.53554C40.3703 8.55136 40.8505 9.9181 40.8505 11.6358V20.224H37.8584V11.8297C37.8584 10.8877 37.6183 10.149 37.1381 9.61335C36.6579 9.07773 36.0023 8.80993 35.1711 8.80993C34.2292 8.80993 33.4904 9.12391 32.9548 9.75187C32.4192 10.3614 32.1514 11.2664 32.1514 12.4669V20.224H29.1593V11.8297C29.1593 10.8693 28.9285 10.1305 28.4667 9.61335C28.0235 9.07773 27.3863 8.80993 26.5551 8.80993C25.6317 8.80993 24.8836 9.12391 24.3111 9.75187C23.757 10.3798 23.48 11.2848 23.48 12.4669V20.224H20.4879V6.37196H23.48V8.03421C24.3665 6.68594 25.6871 6.01181 27.4417 6.01181C29.2147 6.01181 30.5261 6.74135 31.3757 8.20044C32.2807 6.74135 33.6936 6.01181 35.6144 6.01181Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
            <path
                d="M51.893 6.01181C53.7769 6.01181 55.4022 6.72288 56.7689 8.14503C58.1172 9.54871 58.7913 11.2664 58.7913 13.298C58.7913 15.3481 58.1172 17.075 56.7689 18.4787C55.4207 19.8824 53.7953 20.5842 51.893 20.5842C49.8059 20.5842 48.1899 19.8085 47.0448 18.2571V25.7649H44.0527V6.37196H47.0448V8.36666C48.1714 6.79676 49.7875 6.01181 51.893 6.01181ZM48.2914 16.484C49.1226 17.3151 50.1661 17.7307 51.422 17.7307C52.6779 17.7307 53.7215 17.3151 54.5526 16.484C55.3837 15.6344 55.7993 14.5724 55.7993 13.298C55.7993 12.0236 55.3837 10.9709 54.5526 10.1397C53.7215 9.29013 52.6779 8.86534 51.422 8.86534C50.1661 8.86534 49.1226 9.29013 48.2914 10.1397C47.4603 10.9709 47.0448 12.0236 47.0448 13.298C47.0448 14.5539 47.4603 15.6159 48.2914 16.484Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
            <path
                d="M64.4951 20.224H61.5031V0H64.4951V20.224Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
            <path
                d="M81.3503 14.5724H70.3241C70.5457 15.6067 71.0444 16.4101 71.8201 16.9827C72.6143 17.5552 73.5931 17.8415 74.7567 17.8415C76.3266 17.8415 77.5179 17.2689 78.3306 16.1238L80.7962 17.5644C79.448 19.5776 77.4256 20.5842 74.729 20.5842C72.4942 20.5842 70.675 19.9008 69.2713 18.5341C67.8861 17.1489 67.1935 15.4035 67.1935 13.298C67.1935 11.2479 67.8769 9.521 69.2436 8.11732C70.5919 6.71365 72.3465 6.01181 74.5074 6.01181C76.5206 6.01181 78.1828 6.72288 79.4941 8.14503C80.8055 9.56718 81.4611 11.2941 81.4611 13.3257C81.4611 13.6951 81.4242 14.1107 81.3503 14.5724ZM70.2964 12.1344H78.4414C78.2382 11.0447 77.7765 10.2044 77.0562 9.61335C76.3543 9.02233 75.4955 8.72682 74.4797 8.72682C73.3715 8.72682 72.448 9.03156 71.7093 9.64105C70.9705 10.2505 70.4995 11.0817 70.2964 12.1344Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
            <path
                d="M83.3478 14.5724H94.3741C94.1525 15.6067 93.6538 16.4101 92.8781 16.9827C92.0839 17.5552 91.105 17.8415 89.9414 17.8415C88.3715 17.8415 87.1802 17.2689 86.3676 16.1238L83.9019 17.5644C85.2502 19.5776 87.2726 20.5842 89.9691 20.5842C92.2039 20.5842 94.0232 19.9008 95.4269 18.5341C96.8121 17.1489 97.5047 15.4035 97.5047 13.298C97.5047 11.2479 96.8213 9.521 95.4546 8.11732C94.1063 6.71365 92.3517 6.01181 90.1908 6.01181C88.1776 6.01181 86.5153 6.72288 85.204 8.14503C83.8927 9.56718 83.237 11.2941 83.237 13.3257C83.237 13.6951 83.274 14.1107 83.3478 14.5724ZM94.4018 12.1344H86.2568C86.4599 11.0447 86.9217 10.2044 87.642 9.61335C88.3438 9.02233 89.2026 8.72682 90.2185 8.72682C91.3266 8.72682 92.2501 9.03156 92.9889 9.64105C93.7277 10.2505 94.1986 11.0817 94.4018 12.1344Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
            <path
                d="M115.264 6.01181C116.834 6.01181 118.099 6.51972 119.059 7.53554C120.02 8.55136 120.5 9.9181 120.5 11.6358V20.224H117.508V11.8297C117.508 10.8877 117.268 10.149 116.788 9.61335C116.307 9.07773 115.652 8.80993 114.821 8.80993C113.879 8.80993 113.14 9.12391 112.604 9.75187C112.069 10.3614 111.801 11.2664 111.801 12.4669V20.224H108.809V11.8297C108.809 10.8693 108.578 10.1305 108.116 9.61335C107.673 9.07773 107.036 8.80993 106.205 8.80993C105.281 8.80993 104.533 9.12391 103.961 9.75187C103.407 10.3798 103.129 11.2848 103.129 12.4669V20.224H100.137V6.37196H103.129V8.03421C104.016 6.68594 105.337 6.01181 107.091 6.01181C108.864 6.01181 110.176 6.74135 111.025 8.20044C111.93 6.74135 113.343 6.01181 115.264 6.01181Z"
                fill={
                    theme.palette.mode === 'dark'
                        ? theme.palette.common.white
                        : '#1B1B1B'
                }
            />
        </svg>
    );
};

export default Logo;
