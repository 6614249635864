import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import * as muiColors from '@mui/material/colors';
import { useField } from 'formik';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useDropzone } from 'react-dropzone';

export interface FileInputProps {
    fieldName: string;
    labelKey: string;
    multiple?: boolean;
    maxSize?: number;
    accept?: Record<string, string[]>;
    maxFiles?: number;
}

const filesEqual = (left: File, right: File): boolean => {
    return (
        left.name === right.name ||
        left.size === right.size ||
        left.lastModified === right.lastModified
    );
};
const FileInput = ({
    fieldName,
    labelKey,
    multiple = false,
    maxSize = Infinity,
    accept = undefined,
    maxFiles = 0,
}: FileInputProps) => {
    const [field, meta, helpers] = useField<any>(fieldName);

    const files: File[] = Array.isArray(field.value)
        ? field.value
        : field.value != null
          ? [field.value]
          : [];

    const {
        getRootProps,
        getInputProps,
        fileRejections,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDropAccepted: (acceptedFiles) => {
            helpers.setValue([...field.value, ...acceptedFiles]);
        },
        multiple,
        maxSize,
        accept,
        maxFiles,
        validator: (file) => {
            if (files.some((existing) => filesEqual(existing, file))) {
                return {
                    code: 'duplicate-file',
                    message: 'This file is already attached.',
                };
            }
            return null;
        },
    });

    const style = {
        borderColor: isDragReject
            ? muiColors.red[600]
            : isDragAccept
              ? muiColors.green[600]
              : isFocused
                ? muiColors.blue[600]
                : undefined,
    };

    return (
        <FormControl fullWidth error={Boolean(meta.touched && meta.error)}>
            <section className="container">
                <div
                    {...getRootProps({
                        style,
                        className: 'file-dragzone-root',
                    })}
                >
                    <input {...getInputProps()} />
                    <Typography variant="h5" component="p">
                        <FormattedMessage id={labelKey} />
                    </Typography>
                    <Typography variant="body2">
                        <FormattedMessage id="file-drag-drop" />
                    </Typography>
                </div>
                {fileRejections.length ? (
                    <List>
                        {fileRejections.map(({ errors }, index) =>
                            errors.map(({ message }, index2) => (
                                <ListItem key={index * 1000 + index2}>
                                    <ListItemIcon>
                                        <ErrorOutlineIcon color="error" />
                                    </ListItemIcon>
                                    <ListItemText sx={{ color: 'inherit' }}>
                                        <FormHelperText error>
                                            {message}
                                        </FormHelperText>
                                    </ListItemText>
                                </ListItem>
                            ))
                        )}
                    </List>
                ) : null}
                <aside>
                    <List>
                        {files.map((file, index) => (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            const copy = files.filter(
                                                (other) =>
                                                    !filesEqual(file, other)
                                            );
                                            helpers.setValue(
                                                multiple
                                                    ? copy
                                                    : copy[0] ?? null
                                            );
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={file.name} />
                                <FormattedNumber
                                    value={parseInt(
                                        (file.size / 1024).toFixed(0)
                                    )}
                                    style="unit"
                                    unit="kilobyte"
                                    unitDisplay="narrow"
                                />
                            </ListItem>
                        ))}
                    </List>
                </aside>
            </section>
            {meta.touched && meta.error && (
                <FormHelperText error>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default FileInput;
