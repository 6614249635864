import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

import AnimateButton from 'ui-component/extended/AnimateButton';

export type IButtonProps = PropsWithChildren<{
    animate?: boolean;
    messageId: string | null;
    wrapperStyle?: React.CSSProperties;
}>;

function ButtonWithTooltip(
    props: IButtonProps & React.ComponentProps<typeof Button>
): React.ReactElement;
function ButtonWithTooltip<P extends { disabled?: boolean }>(
    props: IButtonProps & {
        component: React.ComponentType<P>;
    } & P
): React.ReactElement;
function ButtonWithTooltip<P extends { disabled?: boolean }>({
    disabled,
    messageId,
    children,
    component = Button,
    animate = false,
    wrapperStyle,
    ...btnProps
}: PropsWithChildren<
    IButtonProps &
        P & {
            component?: React.ComponentType<P>;
        }
>) {
    const { formatMessage } = useIntl();
    const ButtonType = component;
    const message = messageId ? formatMessage({ id: messageId }) : '';

    const inner = (
        <ButtonType
            disabled={disabled}
            aria-label={message}
            {...(btnProps as any)}
        >
            {children}
        </ButtonType>
    );
    const animated = animate ? <AnimateButton>{inner}</AnimateButton> : inner;
    return (
        <Tooltip title={message}>
            <span style={wrapperStyle}>{animated}</span>
        </Tooltip>
    );
}
export default ButtonWithTooltip;
