import type { PaletteMode } from '@mui/material';

const config: {
    basename: string;
    defaultPath: { user: string; unauth: string };
    fontFamily: string;
    borderRadius: number;
    outlinedFilled: boolean;
    theme: PaletteMode;
    presetColor: string;
    i18n: string;
    rtlLayout: boolean;
    domain: string;
    google: {
        ClientID: string;
    };
    simpleemSSO: {
        clientId: string;
        beginUri: string;
    };
} = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: {
        user: '/video-list',
        unauth: '/login',
    },
    fontFamily: `'Gilroy', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    domain: process.env.REACT_APP_CONFIGS_DOMAIN!,
    google: {
        ClientID: process.env.REACT_APP_CONFIGS_GOOGLE_CLIENT_ID!,
    },
    simpleemSSO: {
        clientId: process.env.REACT_APP_CONFIGS_SIMPLEEM_SSO_CLIENT_ID!,
        beginUri: process.env.REACT_APP_CONFIGS_SIMPLEEM_SSO_URL!,
    },
};

export default config;
