import { FormattedMessage } from 'react-intl';
import { IconMail } from '@tabler/icons';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

import type { NavItemType } from 'types';

export const videoList: NavItemType = {
    id: 'video-list',
    title: <FormattedMessage id="video-list" />,
    type: 'item',
    url: '/video-list',
    icon: VideoLibraryOutlinedIcon,
    children: [
        {
            id: 'video-info',
            title: <FormattedMessage id="video-info" />,
            type: 'item',
            url: '/video-list/video-info/:id',
        },
    ],
    subscriptionOnly: true,
};
export const contactUs: NavItemType = {
    id: 'contact-us',
    title: <FormattedMessage id="contact-us" />,
    type: 'item',
    url: 'mailto:info@simpleem.com',
    icon: IconMail,
    target: true,
};
