import type {
    ForwardRefExoticComponent,
    RefAttributes,
    ComponentProps,
    PropsWithChildren,
} from 'react';
import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { MENU_OPEN, SET_MENU } from 'store/actions';
import type { DefaultRootStateProps, LinkTarget, NavItemType } from 'types';
import useAuth from 'hooks/useAuth';

export interface NavItemProps {
    item: NavItemType;
    level: number;
}

const NavItem = ({ item, level }: NavItemProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector(
        (state: DefaultRootStateProps) => state.customization
    );
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation();

    const { user } = useAuth();
    const noAttempts = !user || user.attemptsRemaining < 1;

    const Icon = item?.icon as any;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width:
                    customization.isOpen.findIndex((id) => id === item?.id) > -1
                        ? 8
                        : 6,
                height:
                    customization.isOpen.findIndex((id) => id === item?.id) > -1
                        ? 8
                        : 6,
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const itemTarget: LinkTarget = '_self';

    let listItemProps: {
        component:
            | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
            | string;
        href?: string;
        target?: LinkTarget;
    } = {
        component: forwardRef(
            (props: Omit<ComponentProps<typeof Link>, 'to'>, ref) => (
                <Link to={item.url!} target={itemTarget} ref={ref} {...props}>
                    {props.children}
                </Link>
            )
        ),
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }
    if (item.target) {
        listItemProps = {
            component: forwardRef(
                (
                    props: PropsWithChildren<RefAttributes<HTMLAnchorElement>>,
                    ref
                ) => (
                    <a
                        ref={ref}
                        {...props}
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {props.children}
                    </a>
                )
            ),
        };
    }

    const itemHandler = (id: string) => {
        dispatch({ type: MENU_OPEN, id });
        matchesSM && dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, [location]);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor:
                    level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`,
            }}
            selected={
                customization.isOpen.findIndex((id) => id === item.id) > -1
            }
            onClick={() => {
                itemHandler(item.id!);
            }}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={
                            customization.isOpen.findIndex(
                                (id) => id === item.id
                            ) > -1
                                ? 'h5'
                                : 'body1'
                        }
                        color="inherit"
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography
                            variant="caption"
                            sx={{ ...theme.typography.subMenuCaption }}
                            display="block"
                            gutterBottom
                        >
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.withAttemptsOnly && noAttempts && (
                <Tooltip
                    title={'You have reached max attempts. Subscribe for more.'}
                    describeChild
                >
                    <LockIcon />
                </Tooltip>
            )}
        </ListItemButton>
    );
};

export default NavItem;
