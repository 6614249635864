import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import Loadable from 'ui-component/Loadable';

import OtherRoutes from './MainRoutes';
import LOGIN_ROUTES from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

import './styles.scss';

const PagesLanding = Loadable(
    lazy(async () => await import('views/pages/landing'))
);
const NotFound = Loadable(
    lazy(async () => await import('views/pages/not-found/NotFound'))
);
const GameScreen = Loadable(lazy(async () => await import('views/GameScreen')));

export default function ThemeRoutes() {
    return useRoutes([
        {
            path: '/',
            element: <PagesLanding />,
        },
        AuthenticationRoutes,
        ...LOGIN_ROUTES,
        OtherRoutes,
        {
            path: '*',
            element: <NotFound />,
        },
        {
            path: '/game',
            element: <GameScreen />,
        },
    ]);
}
