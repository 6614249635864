import type { ComponentProps } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
import { FormattedMessage } from 'react-intl';

export interface TextInputProps extends ComponentProps<typeof OutlinedInput> {
    fieldName: string;
    labelKey: string;
}

const TextInput = ({
    fieldName,
    labelKey,
    required,
    id,
    ...extraProps
}: TextInputProps) => {
    const [field, meta] = useField<string>(fieldName);

    const label = (
        <FormattedMessage
            id={labelKey}
            values={{
                value: required ? '*' : undefined,
            }}
        />
    );

    return (
        <FormControl fullWidth error={Boolean(meta.touched && meta.error)}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                value={meta.value}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
                label={label}
                required={required}
                {...extraProps}
            />
            {meta.touched && meta.error && (
                <FormHelperText error>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default TextInput;
