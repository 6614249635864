import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';

import AuthWrapper2 from 'views/pages/authentication/AuthWrapper2';

const MinimalLayout = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthWrapper2>
            <Grid
                container
                alignItems={matchDownSM ? 'center' : 'flex-start'}
                justifyContent={matchDownSM ? 'center' : 'space-between'}
                width="100%"
                p={2}
            >
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                >
                    <Outlet />
                </Grid>
            </Grid>
        </AuthWrapper2>
    );
};

export default MinimalLayout;
