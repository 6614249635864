import { lazy } from 'react';

import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import AuthLayout from 'layout/AuthLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(
    lazy(
        async () =>
            await import('views/pages/authentication/authentication2/Login2')
    )
);
const AuthForgotPassword = Loadable(
    lazy(
        async () =>
            await import(
                'views/pages/authentication/authentication2/ForgotPassword2'
            )
    )
);
const AuthRegister = Loadable(
    lazy(
        async () =>
            await import('views/pages/authentication/authentication2/Register2')
    )
);
const SimpleemSSO = Loadable(
    lazy(async () => ({
        default: (
            await import('views/pages/authentication/auth-forms/AuthSocial')
        ).SimpleemHandler,
    }))
);

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <AuthLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />,
        },
        {
            path: '/register',
            element: <AuthRegister />,
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword />,
        },
    ],
};

const RawLoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/simpleem-oauth/login',
            element: <SimpleemSSO type="login" />,
        },
        {
            path: '/simpleem-oauth/signup',
            element: <SimpleemSSO type="signup" />,
        },
    ],
};

export default [LoginRoutes, RawLoginRoutes];
