import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import useAuth from 'hooks/useAuth';
import type { GuardProps } from 'types';
import useDefaultPath from 'hooks/useDefaultPath';

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }: GuardProps) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const defaultPath = useDefaultPath();

    useEffect(() => {
        if (user) {
            navigate(defaultPath);
        }
    }, [user, navigate, defaultPath]);

    return children;
};

export default GuestGuard;
