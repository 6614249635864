import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { SNACKBAR_OPEN } from 'store/actions';

const useAlert = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const showAlert = useCallback(
        (
            severity: 'success' | 'error',
            messageId: string,
            formatParams?: Record<string, string>
        ) => {
            return dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: formatMessage({ id: messageId }, formatParams),
                variant: 'alert',
                alertSeverity: severity,
            });
        },
        [dispatch, formatMessage]
    );
    return { showAlert };
};
export default useAlert;
