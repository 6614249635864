import ReplayIcon from '@mui/icons-material/Replay';

import { contactUs, videoList } from './common';
const user = {
    id: 'user',
    title: '',
    type: 'group',
    children: [
        {
            id: 'game',
            title: 'Try Again',
            type: 'item',
            url: '/game',
            icon: ReplayIcon,
            withAttemptsOnly: true,
        },
        videoList,
        contactUs,
    ],
};

export default user;
