import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationScroll = ({
    children,
}: PropsWithChildren<unknown>): JSX.Element => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [pathname]);

    return children as any;
};

export default NavigationScroll;
