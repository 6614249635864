// action - state management

const initialState: { isLoadedNewVideo: boolean } = {
    isLoadedNewVideo: false,
};

export interface ListReducerActionProps {
    type: string;
    payload?: boolean;
}

const videoUploaded = (
    state = initialState,
    action: ListReducerActionProps
) => {
    switch (action.type) {
        case 'ADDED_NEW_VIDEO_CHECK': {
            return {
                ...state,
                isLoadedNewVideo: action?.payload,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default videoUploaded;
