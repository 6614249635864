/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { darken, lighten } from '@mui/system';
import * as muiColors from '@mui/material/colors';
import type { Theme } from '@mui/system';

import type { CustomTypography } from 'themes/types';

declare module '@mui/material/Chip' {
    interface ChipPropsVariantOverrides {
        filledLight: true;
    }
}

/**
 * Override Alert colors to work with our "theme".
 * Unfortunately, we use theme colors that do not play well with MUI
 * color system. Ideally, we should fix that instead, but such a task
 * is much less trivial. Warning color is especially troublesome.
 *
 * Additionally, we add a border to "standard" variant.
 */
const makeAlertOverrides = (color: string) => {
    const capColor = color[0].toUpperCase() + color.slice(1);
    const specialColor = `alert${capColor}`;
    const iconClass = 'MuiAlert-icon';

    return {
        [`standard${capColor}`]: ({ theme }: { theme: Theme }) => {
            const getColor = theme.palette.mode === 'light' ? darken : lighten;
            const getBackgroundColor =
                theme.palette.mode === 'light' ? lighten : darken;
            const actualColor =
                specialColor in theme.palette ? specialColor : color;
            return {
                // Original uses 0.6 adjustment for color, resp.
                color: getColor(theme.palette[actualColor].light, 0.8),
                border: `1px solid ${theme.palette[actualColor].main}`,
                backgroundColor: getBackgroundColor(
                    theme.palette[actualColor].light,
                    0.9
                ),
                [`& .${iconClass}`]: {
                    color: theme.palette[actualColor].main,
                },
            };
        },
        [`outlined${capColor}`]: ({ theme }: { theme: Theme }) => {
            const getColor = theme.palette.mode === 'light' ? darken : lighten;
            const actualColor =
                specialColor in theme.palette ? specialColor : color;
            return {
                color: getColor(theme.palette[actualColor].light, 0.6),
                border: `1px solid ${theme.palette[actualColor].light}`,
                [`& .${iconClass}`]: {
                    color: theme.palette[actualColor].main,
                },
            };
        },
        [`filled${capColor}`]: ({ theme }: { theme: Theme }) => {
            const actualColor =
                specialColor in theme.palette ? specialColor : color;
            return {
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? theme.palette[actualColor].dark
                        : theme.palette[actualColor].main,
                color: theme.palette.getContrastText(
                    theme.palette[actualColor].main
                ),
            };
        },
    };
};

export default function componentStyleOverrides(theme: CustomTypography) {
    const bgColor =
        theme?.customization?.navType === 'dark'
            ? theme.colors?.darkBackground
            : theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '10px',
                },
                containedSecondary: {
                    backgroundColor: muiColors.blue[700],
                },
                outlinedSecondary: {
                    borderColor: muiColors.blue[700],
                    color: muiColors.blue[700],
                },
                textSecondary: {
                    color: muiColors.blue[700],
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '12px 12px 10px',
                },
                title: {
                    fontSize: '20px',
                    fontWeight: 700,
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '12px',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '12px',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center',
                    '& a': {
                        color: 'inherit',
                    },
                },
                ...makeAlertOverrides('warning'),
                ...makeAlertOverrides('info'),
                ...makeAlertOverrides('success'),
                ...makeAlertOverrides('error'),
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        '&:hover': {
                            backgroundColor: theme.menuSelectedBack,
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected,
                        },
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected,
                        },
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: muiColors.grey[700],
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme?.customization?.outlinedFilled
                        ? bgColor
                        : 'transparent',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor:
                            theme?.customization?.navType === 'dark'
                                ? theme.colors?.darkTextPrimary! + 28
                                : theme.colors?.grey400,
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight,
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1,
                    },
                },
                input: {
                    fontWeight: 500,
                    background: theme?.customization?.outlinedFilled
                        ? bgColor
                        : 'transparent',
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0,
                        },
                    },
                },
                inputAdornedStart: {
                    paddingLeft: 4,
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color:
                            theme?.customization?.navType === 'dark'
                                ? theme.colors?.darkTextPrimary! + 50
                                : theme.colors?.grey300,
                    },
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px',
                },
                valueLabel: {
                    color:
                        theme?.customization?.navType === 'dark'
                            ? theme?.colors?.primaryMain
                            : theme?.colors?.primaryLight,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background:
                            theme?.customization?.navType === 'dark'
                                ? theme.colors?.darkTextPrimary! + 20
                                : theme.colors?.secondaryLight,
                        borderRadius: 4,
                        color: theme.textDark,
                        '.MuiChip-deleteIcon': {
                            color:
                                theme?.customization?.navType === 'dark'
                                    ? theme.colors?.darkTextPrimary! + 80
                                    : theme.colors?.secondary200,
                        },
                    },
                },
                popper: {
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    boxShadow:
                        '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: theme?.customization?.navType === 'dark' ? 0.2 : 1,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    /** checked not prop
                     *"&.Mui-checked": {
                     *    fontSize: "28px"
                     *}
                     */
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color:
                        theme?.customization?.navType === 'dark'
                            ? theme.colors?.darkLevel1
                            : theme.colors?.primaryDark,
                    background:
                        theme?.customization?.navType === 'dark'
                            ? theme.darkTextPrimary
                            : theme.colors?.primary200,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit',
                    },
                },
                colorError: {
                    backgroundColor: muiColors.red[700],
                    '&.MuiChip-outlined': {
                        backgroundColor: 'unset',
                        color: muiColors.red[700],
                        borderColor: muiColors.red[700],
                    },
                },
                colorWarning: {
                    backgroundColor: muiColors.orange[500],
                    '&.MuiChip-outlined': {
                        backgroundColor: 'unset',
                        color: muiColors.orange[500],
                        borderColor: muiColors.orange[500],
                    },
                },
                colorInfo: {
                    backgroundColor: muiColors.blue[700],
                    '&.MuiChip-outlined': {
                        backgroundColor: 'unset',
                        color: muiColors.blue[700],
                        borderColor: muiColors.blue[700],
                    },
                },
                colorSuccess: {
                    '&.MuiChip-outlined': {
                        backgroundColor: 'unset',
                        color: muiColors.green[800],
                        borderColor: muiColors.green[800],
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'filledLight', color: 'error' },
                    style: {
                        backgroundColor: muiColors.red[100],
                        color: muiColors.red[900],
                        borderColor: muiColors.red[900],
                    },
                },
                {
                    props: { variant: 'filledLight', color: 'info' },
                    style: {
                        backgroundColor: muiColors.blue[100],
                        color: muiColors.blue[900],
                        borderColor: muiColors.blue[900],
                    },
                },
                {
                    props: { variant: 'filledLight', color: 'success' },
                    style: {
                        backgroundColor: muiColors.green[100],
                        color: muiColors.green[900],
                        borderColor: muiColors.green[900],
                    },
                },
                {
                    props: { variant: 'filledLight', color: 'warning' },
                    style: {
                        backgroundColor: muiColors.orange[100],
                        color: muiColors.orange[900],
                        borderColor: muiColors.orange[900],
                    },
                },
                {
                    props: { variant: 'filledLight', color: 'primary' },
                    style: {
                        backgroundColor: muiColors.purple[100],
                        color: muiColors.purple[900],
                        borderColor: muiColors.purple[900],
                    },
                },
                {
                    props: { variant: 'filledLight', color: 'secondary' },
                    style: {
                        backgroundColor: muiColors.indigo[100],
                        color: muiColors.indigo[900],
                        borderColor: muiColors.indigo[900],
                    },
                },
            ] satisfies Array<{
                props: {
                    color:
                        | 'primary'
                        | 'secondary'
                        | 'error'
                        | 'info'
                        | 'success'
                        | 'warning';
                    variant: 'filledLight';
                };
                style: any;
            }> as any,
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    fontSize: '16px',
                },
            },
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14,
                },
            },
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor:
                        theme?.customization?.navType === 'dark'
                            ? theme.colors?.darkPaper
                            : theme.colors?.primaryLight,
                    '& .MuiTabs-flexContainer': {
                        borderColor:
                            theme?.customization?.navType === 'dark'
                                ? theme?.colors?.darkTextPrimary! + 20
                                : theme.colors?.primary200,
                    },
                    '& .MuiTab-root': {
                        color:
                            theme?.customization?.navType === 'dark'
                                ? theme.colors?.darkTextSecondary
                                : theme.colors?.grey900,
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.primaryDark,
                    },
                    '& .Mui-selected': {
                        color: theme.colors?.primaryDark,
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor:
                        theme?.customization?.navType === 'dark'
                            ? theme.colors?.darkTextPrimary! + 20
                            : theme.colors?.grey200,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor:
                        theme?.customization?.navType === 'dark'
                            ? theme.colors?.darkTextPrimary! + 15
                            : theme.colors?.grey200,
                    '&.MuiTableCell-head': {
                        fontSize: '0.875rem',
                        color: theme.heading,
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color:
                        theme?.customization?.navType === 'dark'
                            ? theme.colors?.darkLevel1
                            : theme.paper,
                    background:
                        theme?.customization?.navType === 'dark'
                            ? theme.colors?.grey50
                            : theme.colors?.grey700,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                },
            },
        },
    };
}
