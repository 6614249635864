const dec2hex = (dec: number): string => {
    return ('0' + dec.toString(16)).substr(-2);
};

const generateCodeVerifier = (): string => {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join('');
};

const sha256binary = async (plain: string): Promise<ArrayBuffer> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return await window.crypto.subtle.digest('SHA-256', data);
};

const base64urlencode = (a: ArrayBuffer) => {
    const str = String.fromCharCode.apply(null, new Uint8Array(a) as any);
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const makeChallenge = async (verifier: string): Promise<string> => {
    const hashed = await sha256binary(verifier);
    const base64encoded = base64urlencode(hashed);
    return base64encoded;
};

interface ChallengeParams {
    verifier: string;
    challenge: string;
}

export const PKCE_STORE_KEY = 'simpleem-game-pkce';

const generatePKCEChallenge = async (): Promise<ChallengeParams> => {
    const verifier = generateCodeVerifier();
    const challenge = await makeChallenge(verifier);
    return { verifier, challenge };
};
export default generatePKCEChallenge;
