import type { LazyExoticComponent, ComponentType } from 'react';
import React, { Suspense } from 'react';

import Loader from './Loader';

const Loadable =
    <ComponentProps,>(
        Component:
            | ComponentType<ComponentProps>
            | LazyExoticComponent<ComponentType<ComponentProps>>
    ) =>
    (props: ComponentProps) => (
        <Suspense fallback={<Loader />}>
            {React.createElement(Component as any, props as any)}
        </Suspense>
    );

export default Loadable;
