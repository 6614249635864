import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useField } from 'formik';
import { FormattedMessage } from 'react-intl';

export interface CheckboxInputProps {
    fieldName: string;
    labelKey: string;
    id: string;
    required?: boolean;
    disabled?: boolean;
    labelMessageValues?: Record<string, any>;
}

const CheckboxInput = ({
    fieldName,
    id,
    labelKey,
    required,
    disabled,
    labelMessageValues = {},
}: CheckboxInputProps) => {
    const [field, meta] = useField<boolean>(fieldName);
    const label = (
        <FormattedMessage
            id={labelKey}
            values={{
                value: required ? '*' : undefined,
                ...labelMessageValues,
            }}
        />
    );

    return (
        <FormControl error={Boolean(meta.touched && meta.error)}>
            <FormControlLabel
                control={
                    <Checkbox
                        id={id}
                        checked={meta.value}
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        disabled={disabled}
                    />
                }
                label={label}
            />
            {meta.touched && meta.error && (
                <FormHelperText error>{meta.error}</FormHelperText>
            )}
        </FormControl>
    );
};

export default CheckboxInput;
