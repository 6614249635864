import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { MessageFormatElement } from 'react-intl';
import { IntlProvider } from 'react-intl';

import type { DefaultRootStateProps } from 'types';

// load locales files
const loadLocaleData = async (locale: string) => {
    switch (locale) {
        case 'ru':
            return await import('utils/locales/ru.json');
        default:
            return await import('utils/locales/en.json');
    }
};

export interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const customization = useSelector(
        (state: DefaultRootStateProps) => state.customization
    );
    const [messages, setMessages] = useState<
        | Record<string, string>
        | Record<string, MessageFormatElement[]>
        | undefined
    >();

    useEffect(() => {
        loadLocaleData(customization.locale).then(
            (d: {
                default:
                    | Record<string, string>
                    | Record<string, MessageFormatElement[]>
                    | undefined;
            }) => {
                setMessages(d.default);
            }
        );
    }, [customization.locale]);

    return (
        <>
            {messages && (
                <IntlProvider
                    locale={customization.locale}
                    defaultLocale="en"
                    messages={messages}
                >
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

export default Locales;
