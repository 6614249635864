import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';
import AuthLayout from 'layout/AuthLayout';

const AuthActivate = Loadable(
    lazy(
        async () =>
            await import('views/pages/authentication/auth-forms/AuthActivate')
    )
);
const AuthCheckMailPass = Loadable(
    lazy(
        async () =>
            await import(
                'views/pages/authentication/authentication2/CheckMailPass'
            )
    )
);
const AuthResetPassword = Loadable(
    lazy(
        async () =>
            await import(
                'views/pages/authentication/authentication2/ResetPassword2'
            )
    )
);

const AdminActivated = Loadable(
    lazy(
        async () =>
            await import(
                'views/pages/authentication/authentication2/AuthActivateSuccess'
            )
    )
);
const NoAttempts = Loadable(
    lazy(async () => await import('views/pages/NoAttempts'))
);

const AuthenticationRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
        {
            path: '/activate',
            element: <AuthActivate />,
        },
        {
            path: '/activated',
            element: <AdminActivated />,
        },
        {
            path: '/reset-password',
            element: <AuthResetPassword />,
        },
        {
            path: '/reset-password-check-mail',
            element: <AuthCheckMailPass />,
        },
        {
            path: '/no-attempts',
            element: <NoAttempts />,
        },
    ],
};

export default AuthenticationRoutes;
