import { useSearchParams } from 'react-router-dom';

export const usePassedState = <State>() => {
    const [searchParams] = useSearchParams();

    const passedStateString = searchParams.get('passed_state');

    let passedState: Partial<State> = {};

    if (passedStateString) {
        try {
            passedState = JSON.parse(passedStateString);
        } catch (error) {
            console.error('Error when parsing passed state:', error);
        }
    }

    return passedState;
};

export const encodePassedState = <State>(state: State) =>
    encodeURIComponent(JSON.stringify(state));
