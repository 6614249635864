/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create();

const spinner = `<section class="spinner">
    <div class="spinner-loader">
        <div/>
        <div/>
        <div/>
        <div/>
    </div>
</section>`;
const div = document.createElement('div');

// interceptor for http
axiosServices.interceptors.request.use(
    (request) => {
        div.innerHTML = spinner;
        document.body.appendChild(div);
        return request;
    },
    async (error) => {
        document.body.removeChild(div);
        await Promise.reject(error);
    }
);

axiosServices.interceptors.response.use(
    (response) => {
        document.body.removeChild(div);
        return response;
    },
    async (error) => {
        document.body.removeChild(div);
        await Promise.reject(error.response?.data || 'Wrong Services');
    }
);

export default axiosServices;
